import React from 'react'
import { DataStore } from "@aws-amplify/datastore";
import { Link, useParams, useLocation,useHistory } from "react-router-dom";

import * as RS from 'reactstrap';
import * as BI from 'react-bootstrap-icons';

import { JobOffer } from '../models/'
import JobCreateModal from './JobCreateModal'

const JobReviewCard = () => {
  const location = useLocation();
  const history = useHistory();

  const { id, newJobOfferId } = useParams();
  const [jobOffers, setJobOffers] = React.useState([]);
  const [jobOffer, setJobOffer] = React.useState({
    title: 'pending'
  });


  const [modal, setModal] = React.useState(false);
  const toggle = () => {
    setModal(!modal);
  };

  React.useEffect(() => {
    fetchNewOffer();
    async function fetchNewOffer() {
      const offer = await DataStore.query(JobOffer, newJobOfferId);
      setJobOffer(offer);
    }
  },[newJobOfferId]);

  React.useEffect(() => {
    fetchJobOffers();
    async function fetchJobOffers() {
      const jobOfferData = (await DataStore.query(JobOffer)).filter(
        (j) => j.companyID === id
      );
      setJobOffers(jobOfferData);
    }
    DataStore.observe(JobOffer).subscribe(() => {
      fetchJobOffers();
    });
  }, [id]);


  const createNewAndRedirect = () => {
    setModal(false);
    history.push(`/companyDetail/${id}/createJobOffer`);
  };

  const copyAndCreateJobOffer = (job) => {
    setModal(false);
    saveCloneAndEdit();
    async function saveCloneAndEdit() {
      const clone = new JobOffer(job);
      const saving = JobOffer.copyOf(clone, (item) => {
        item.title = `Kopie von ${clone.title}`;
      });
      await DataStore.save(saving);
      history.push(`/companyDetail/${id}/editJobOffer/${saving.id}`);
    }
  };


	return (
    <>
      <JobCreateModal
        toggle={toggle}
        modal={modal}
        jobOffers={jobOffers}
        createNewAndRedirectFn={createNewAndRedirect}
        copyAndCreateFn={copyAndCreateJobOffer}
      />
      <RS.Row>
        <RS.Col
          className="pt-4 pl-4 offset-sm-1 bg-white"
          sm="5"
          style={{
            borderRightStyle: "solid",
            borderRightColor: "var(--gray-300)",
            borderRightWidth: "1px",
          }}
        >
          <h3>{jobOffer.title}</h3>
          <p>
            {new Date(jobOffer.jobStartDate).toLocaleString().split(",")[0]} •{" "}
            {jobOffer.site?.map((loc) => (
              <span>{loc} • </span>
            ))}
            {jobOffer.contractType}
          </p>
        </RS.Col>
        <RS.Col sm="4" className="bg-white pt-3">
          <RS.Table
            borderless
            style={{ color: "var(--gray-600)", fontSize: "14px" }}
          >
            <tbody>
              <tr>
                <td>Job-ID</td>
                <td>{jobOffer.nanoId}</td>
              </tr>
              <tr>
                <td>Status</td>
                <td>
                  {" "}
                  {jobOffer.isEnabled ? (
                    <span className="text-success">● Online</span>
                  ) : (
                    <>
                      <span className="text-warning">● </span>
                      <span>keineFreigabe</span>
                    </>
                  )}
                </td>
              </tr>
              <tr>
                <td>Veröffentlichung</td>
                <td>
                  {new Date(jobOffer.validFrom).toLocaleString().split(",")[0]}
                </td>
              </tr>
              <tr>
                <td>Ablauf</td>
                <td>
                  {new Date(jobOffer.validUntil).toLocaleString().split(",")[0]}
                </td>
              </tr>
            </tbody>
          </RS.Table>
        </RS.Col>
      </RS.Row>
      <RS.Row>
        <RS.Col
          sm="9"
          className="pt-2 pb-2 pl-4 offset-sm-1 bg-white"
          style={{
            borderTopStyle: "solid",
            borderTopColor: "var(--gray-300)",
            borderTopWidth: "1px",
          }}
        >
          <Link to={`/companyDetail/${id}/editJobOffer/${jobOffer.id}`}>
            <BI.Pen /> Bearbeiten
          </Link>
        </RS.Col>
      </RS.Row>
      <RS.Row>
        <RS.Col sm="9" className="pt-2 pb-2 pl-4 offset-sm-1 text-right">
          <Link className="pr-5 text-dark" to={`/companyDetail/${id}/`}>
            Zur Übersicht{" "}
            <BI.ChevronRight
              className="text-dark"
              style={{ fontSize: "11px" }}
            />
          </Link>
          <Link
            className="text-dark"
            onClick={() => { toggle() }}
          >
            Weiteres Stellenangebot{" "}
            <BI.ChevronRight
              className="text-dark"
              style={{ fontSize: "11px" }}
            />
          </Link>
        </RS.Col>
      </RS.Row>
    </>
  );
}

export default JobReviewCard
