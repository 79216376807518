const CareerLevelData = [
  {
    id: 0,
    label: "Karrierestufe wählen",
  },
  {
    id: 1,
    label: "Berufseinsteiger",
  },
  {
    id: 2,
    label: "Berufserfahrene",
  },
];

export default CareerLevelData;
