import React, { useState } from "react";
import { Route, Link, Switch, useParams, useRouteMatch, useLocation, useHistory } from "react-router-dom";

import { DataStore } from "@aws-amplify/datastore";
import { Company, JobOffer, Location } from "../models";
import ContractTypeData from "../data/ContractTypeData";

import Main from "../components/Main";
import HeaderBar from "../components/HeaderBar";
import PageHeader from "../components/PageHeader";
import JobOfferTable from "../components/JobOfferTable";
import Sidebar from "../components/Sidebar";
import SidebarItem from "../components/SidebarItem";
import JobForm from "../components/JobForm";
import JobReviewCard from "../components/JobReviewCard";


import JobEditForm from "../components/JobEditForm";
import * as RS from "reactstrap";
import * as BI from "react-bootstrap-icons";
import JobCreateModal from "../components/JobCreateModal";

function CompanyDetail({user}) {
  const location = useLocation();
  const history = useHistory()
	const [activeCompany,setActiveCompany] = useState({});
  const [jobOffers,setJobOffers] = useState([]);
  const [sites, setSites] = useState([]);
  const [filteredOffers, setFilteredOffers] = useState([]);

  let { path, } = useRouteMatch();
  let { id } = useParams();

  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };

  const textFilter = (event) => {
    const remainingByTitle = jobOffers.filter(j => j.title.toLowerCase().includes(event.target.value.toLowerCase()));
    const remainingBySite = jobOffers.filter(j2 => { return j2.site.join("").toLowerCase().includes(event.target.value.toLowerCase())} );

    setFilteredOffers([...new Set([...remainingByTitle, ...remainingBySite])]);
  }

	React.useEffect(() => {
    fetchCompany();
		async function fetchCompany() {
      const companyData = await DataStore.query(Company,id);
      const siteData = await DataStore.query(Location);
      setActiveCompany(companyData);
      setSites(siteData);
    }
		DataStore.observe(Company).subscribe(() => {
			fetchCompany();
		});
  }, [id]);

  React.useEffect(() => {
    fetchJobOffers();
    async function fetchJobOffers() {
      const jobOfferData =(await DataStore.query(JobOffer)).filter(j => j.companyID === id);
      setJobOffers(jobOfferData);
      setFilteredOffers(jobOfferData);
    }
    DataStore.observe(JobOffer).subscribe(() => {
      fetchJobOffers();
    });
  },[id]);

  const createNewAndRedirect = () => {
    setModal(false);
    history.push(`${location.pathname}/createJobOffer`);
  }

  const copyAndCreateJobOffer = (job) => {
    setModal(false);
    saveCloneAndEdit();
    async function saveCloneAndEdit() {
      const clone = new JobOffer(job);
      const saving = JobOffer.copyOf(clone, item => {
        item.title = `Kopie von ${clone.title}`
      })
      await DataStore.save(saving);
      history.push(`${location.pathname}/editJobOffer/${saving.id}`);
    };
  }

  const filterBySite = (event) => {
    if(event.target.value === 'Alle anzeigen') {
      setFilteredOffers(jobOffers);
    } else {
      const filteredOffers = jobOffers.filter((offer) => {
        return offer.site.find((a) => a.includes(event.target.value));
      });
      setFilteredOffers(filteredOffers);
    }
  }

  const filterByContract = (event) => {
    if(event.target.value === 'Vertragsart wählen') {
      setFilteredOffers(jobOffers);
    } else {
      const appliedFilterOffers = jobOffers.filter((offer) => {
        return offer.contractType === event.target.value;
      });
      setFilteredOffers(appliedFilterOffers);
    }
  }

  return (
    <>
      <RS.Container className="container-fluid">
        <HeaderBar
          className="nav"
          user={user}
          breadcrumb={
            <>
              <RS.BreadcrumbItem>
                <Link to="/">Home</Link>
              </RS.BreadcrumbItem>
              <RS.BreadcrumbItem>
                <Link to="/overview">Overview</Link>
              </RS.BreadcrumbItem>
              <RS.BreadcrumbItem active>
                {activeCompany?.name}
              </RS.BreadcrumbItem>
            </>
          }
        ></HeaderBar>
        <RS.Row>
          <Sidebar>
            <SidebarItem label="Kontoübersicht" link="/overview" />
          </Sidebar>
          <Main>
            <Switch>
              <Route exact path={path}>
                <PageHeader
                  header="Meine offenen Stellen"
                  subheader={activeCompany?.name}
                >
                  <RS.Button
                    className="bg-primary text-white float-right"
                    onClick={() => {
                      toggle();
                    }}
                  >
                    <BI.Plus style={{ fontSize: "1.7rem" }} />
                    Neues Stellenangebot
                  </RS.Button>
                  <JobCreateModal toggle={toggle} modal={modal} jobOffers={jobOffers} copyAndCreateFn={copyAndCreateJobOffer} createNewAndRedirectFn={createNewAndRedirect} />
                </PageHeader>
                <RS.Container>
                  <RS.Row>
                    <RS.Col key="1" className="bg-white p-3 pb-1 col-sm-2">
                      <RS.FormGroup>
                        <RS.Label for="siteSelect">
                          Niederlassungen:
                          <RS.Input
                            className="mt-2 pr-2 mr-2"
                            type="select"
                            name="siteSelect"
                            id="siteSelect"
                            onChange={filterBySite}
                          >
                            <option>Alle anzeigen</option>
                            {sites.map((site) => (
                              <option key={site.address}>{site.address}</option>
                            ))}
                          </RS.Input>
                        </RS.Label>
                      </RS.FormGroup>
                    </RS.Col>
                    <RS.Col key="2" className="bg-white p-3 pb-1">
                      <RS.FormGroup key="a">
                        <RS.Label for="contractSelect" key="b">
                          Vertragsart:
                          <RS.Input
                            key="c"
                            className="mt-2 pr-2 mr-2"
                            type="select"
                            name="contractSelect"
                            id="contractSelect"
                            onChange={filterByContract}
                          >
                            {ContractTypeData.map((type) => (
                              <option key={type.id}>{type.label}</option>
                            ))}
                          </RS.Input>
                        </RS.Label>
                      </RS.FormGroup>
                    </RS.Col>
                    <RS.Col key="3" className="bg-white p-3 pb-1">
                      <RS.FormGroup style={{marginTop: '32px'}}>
                        <RS.InputGroup>
                          <RS.Input placeholder="Suche" onChange={textFilter} />
                          <RS.InputGroupAddon addonType="append">
                            <RS.InputGroupText>
                              <BI.Search />
                            </RS.InputGroupText>
                          </RS.InputGroupAddon>
                        </RS.InputGroup>
                      </RS.FormGroup>
                    </RS.Col>
                  </RS.Row>
                </RS.Container>
                <JobOfferTable jobOffers={filteredOffers} />
              </Route>
              <Route exact path="/companyDetail/:id/createJobOffer">
                <PageHeader
                  header="Neues Stellenangebot"
                  subheader={activeCompany?.name}
                ></PageHeader>
                <JobForm company={activeCompany} />
              </Route>
              <Route exact path="/companyDetail/:id/editJobOffer/:jobOfferId">
                <PageHeader
                  header="Stellenangebot bearbeiten"
                  subheader={activeCompany?.name}
                ></PageHeader>
                <JobEditForm company={activeCompany} />
              </Route>
              <Route exact path="/companyDetail/:id/reviewJobOffer/:newJobOfferId">
                <PageHeader
                  header="Stellenangebot erfolgreich erstellt"
                  subheader={activeCompany?.name}
                ></PageHeader>
                <JobReviewCard company={activeCompany} />
              </Route>
            </Switch>
          </Main>
        </RS.Row>
      </RS.Container>
    </>
  );
}

export default CompanyDetail;
