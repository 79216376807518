import React from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Amplify from "aws-amplify";

import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import awsconfig from "./aws-exports";
import Dict from './i18n/aws-translations';

import AWSAuthenticator from './components/AWSAuthenticator';
import Logo from './components/Logo';
import Overview from './screens/Overview';
import CompanyDetail from './screens/CompanyDetail';
import Settings from './screens/Settings';

Amplify.configure(awsconfig);

Amplify.I18n.setLanguage("de");
Amplify.I18n.putVocabularies(Dict);


function App() {
  document.title = 'ONE Job';
  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();

  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, []);

  return authState === AuthState.SignedIn && user ? (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/">
            <Overview user={user} />
          </Route>
          <Route exact path="/overview" user="foobar">
            <Overview user={user} />
          </Route>
          <Route exact path="/settings">
            <Settings user={user} />
          </Route>
          <Route path="/companyDetail/:id" label="Home / Firma">
            <CompanyDetail user={user} />
          </Route>
        </Switch>
      </div>
    </Router>
  ) : (
    <div className="App">
      <Logo />
      <AWSAuthenticator />
    </div>
  );
}

export default App;
