import React, { useState } from 'react'

import * as RS from "reactstrap";
import styles from "../theme/RSTable.module.css";

import { DataStore } from "@aws-amplify/datastore";
import { JobOffer } from "../models";
import { Link, useLocation } from "react-router-dom";

import JobContextMenu from "./JobContextMenu";

const JobOfferTable = ({jobOffers}) => {
  const location = useLocation();
  const [offerToDelete, setOfferToDelete] = useState('');
  const [modal, setModal] = useState(false);

	const toggle = () => {
		setModal(!modal);
  };

	async function handleDelete() {
		const jobToDelete = await DataStore.query(JobOffer, offerToDelete.id);
		DataStore.delete(jobToDelete);
		toggle();
  }

	if (jobOffers && jobOffers.length > 0) {
		return (
      <RS.Table className={styles.RSTable} style={{ tableLayout: "fixed" }}>
        <thead>
          <tr>
            <th style={{ width: "50px" }} className="pt-3 pb-3 text-center">
              Status
            </th>
            <th style={{ width: "250px" }} className="pt-3 pb-3">
              Stelle
            </th>
            <th style={{ width: "150px" }} className="pt-3 pb-3">
              Platzierung
            </th>
            <th style={{ width: "150px" }} className="pt-3 pb-3">
              Standort(e)
            </th>
            <th style={{ width: "100px" }} className="pt-3 pb-3">
              Vertragsart
            </th>
            <th style={{ width: "100px" }} className="pt-3 pb-3">
              Erstellt am
            </th>
            <th style={{ width: "55px" }} className="pt-3 pb-3">
              Menü
            </th>
          </tr>
        </thead>
        <tbody style={{ fontSize: "14px" }}>
          {jobOffers.map((jobOffer) => (
            <tr key={jobOffer.id}>
              <td style={{ width: "50px" }} className="text-center">
                {jobOffer.isEnabled ? (
                  <span className="text-success">●</span>
                ) : (
                  <span className="text-warning">●</span>
                )}
              </td>
              <td style={{ width: "250px" }}>
                <Link
                  to={`${location.pathname}/editJobOffer/${jobOffer.id}`}
                  className="text-body"
                >
                  <strong>{jobOffer.title} </strong>
                </Link>
              </td>
              <td style={{ width: "150px" }}>
                {jobOffer.promoteOnCorporate ? (
                  <img src="/foo.svg" alt="Logo Matthäi Corporate" />
                ) : (
                  <></>
                )}
                {jobOffer.promoteOnXing ? (
                  <img src="/xing.png" alt="Logo Xing" />
                ) : (
                  <></>
                )}
                {jobOffer.promoteOnLinkedIn ? (
                  <img src="/linkedin.png" alt="Logo Xing" />
                ) : (
                  <></>
                )}
                {jobOffer.promoteOnIndeed ? (
                  <img src="/indeed.png" alt="Logo Xing" />
                ) : (
                  <></>
                )}
              </td>
              <td style={{ width: "150px" }}>
                {jobOffer.site.map((site, i, arr) =>
                  i < arr.length - 1 ? site + " · " : site
                )}
              </td>
              <td style={{ width: "100px" }}>{jobOffer.contractType}</td>
              <td style={{ width: "100x" }}>
                {
                  new Date(jobOffer.createdAt)
                    .toLocaleString("de-de")
                    .split(",")[0]
                }
              </td>
              <td style={{ width: "55px" }}>
                <JobContextMenu
                  job={jobOffer}
                  deleteHandler={setOfferToDelete}
                  location={location}
                  modalToggle={toggle}
                />
              </td>
            </tr>
          ))}
        </tbody>
        <RS.Modal isOpen={modal} toggle={toggle}>
          <RS.ModalHeader toggle={toggle}>
            Stellenangebot löschen
          </RS.ModalHeader>
          <RS.ModalBody>
            Soll das Stellenangebot <br />
            <strong>{offerToDelete.title}</strong> <br />
            wirklich gelöscht werden?
          </RS.ModalBody>
          <RS.ModalFooter style={{ justifyContent: "space-between" }}>
            <RS.Button color="primary" onClick={handleDelete}>
              Löschen
            </RS.Button>{" "}
            <RS.Button color="secondary" onClick={toggle}>
              Nicht löschen
            </RS.Button>
          </RS.ModalFooter>
        </RS.Modal>
      </RS.Table>
    );
	} else {
		return (
      <RS.Card>
        <RS.CardBody>
          <h3>Keine Stellenangebote gefunden</h3>
        </RS.CardBody>
      </RS.Card>
    );
	}
}

export default JobOfferTable
