import React, { useState } from 'react'
import { useHistory, useParams } from "react-router-dom";

import * as RS from 'reactstrap';
import { DataStore } from "@aws-amplify/datastore";
import { Company, JobOffer, Location, FunctionalArea, Specialization } from "../models";
import VariSelect from "./VariSelect";
import SuggestionEditor from "./SuggestionEditor";

import TaskTemplatesData from "../data/TaskTemplatesData";
import QualificationTemplateData from "../data/QualificationTemplateData";
import CareerLevelData from "../data/CareerLevelData";
import ContractTypeData from "../data/ContractTypeData";

const JobEditForm = () => {
  const history = useHistory();
	let { id, jobOfferId } = useParams();
  const [jobOffer, setJobOffer] = React.useState({});
  const [fadeIn, setFadeIn] = useState(false);

  const [company, setCompany] = useState({});
  const [title, setTitle] = useState('');
  const [site, setSite] = useState([]);
  const [functionalAreas, setFunctionalAreas] = useState([]);
  const [specializations, setSpecializations] = useState([]);
  const [careerLevel, setCareerLevel] = useState('');
  const [contractType, setContractType] = useState('');
  const [jobStartDate, setJobStartDate] = useState('');

  const [validFrom, setValidFrom] = useState('');
  const [validUntil, setValidUntil] = useState('');

  const taskTemplates = TaskTemplatesData;
  const [taskData, setTaskData] = useState('');

  const qualificationTemplates = QualificationTemplateData;
  const [qualificationData, setQualificationData] = useState('');
  const [isEnabled, setIsEnabled] = useState(false);


  const hydrateSite = (offer) => {
    if (Array.isArray(offer.site) && offer.site.length > 0) {
      setSite(offer.site.map((a) => ({ id: 1, address: a })));
    } else {
      setSite([]);
    }
  };

  const hydrateFunctionalAreas = (offer) => {
    if (Array.isArray(offer.functionalArea) && offer.functionalArea.length > 0) {
      setFunctionalAreas(offer.functionalArea.map((a) => ({ id: 1, name: a })));
    } else {
      setFunctionalAreas([]);
    }
  };
  const hydrateSpecializations = (offer) => {
    if (Array.isArray(offer.specialization) && offer.specialization.length > 0) {
      setSpecializations(offer.specialization.map((a) => ({ id: 1, name: a })));
    } else {
      setSpecializations([]);
    }
  };

	React.useEffect(() => {
    fetchJobData();
    async function fetchJobData() {
      const offer = await DataStore.query(JobOffer, jobOfferId);
      const company = await DataStore.query(Company, id);
      setJobOffer(offer);
      setCompany(company);
      setTitle(offer.title || "");
      hydrateSite(offer);
      hydrateFunctionalAreas(offer);
      hydrateSpecializations(offer);
      setCareerLevel(offer.careerLevel);
      setContractType(offer.contractType);
      setJobStartDate(offer.jobStartDate);
      setValidFrom(offer.validFrom);
      setValidUntil(offer.validUntil);
      setTaskData(offer.tasks);
      setQualificationData(offer.qualifications);
      setIsEnabled(offer.isEnabled);
    }
  }, [jobOfferId, id]);

  // saves changes to aws datastore
  const updateJobOffer = () => {
    storeData();
    async function storeData() {
      const copy = JobOffer.copyOf(jobOffer, (item) => {
        item.title = title;
        item.site = site.map(a => a.address).flat();
        item.functionalArea = functionalAreas.map(a => a.name).flat();
        item.specialization = specializations.map(a => a.name).flat();
        item.careerLevel = careerLevel;
        item.contractType = contractType;
        item.jobStartDate = jobStartDate;
        item.validFrom = validFrom;
        item.validUntil = validUntil;
        item.tasks = taskData;
        item.qualifications = qualificationData;
        item.isEnabled = isEnabled;
      });
      await DataStore.save(copy);
    }
    setFadeIn(true);
    setTimeout(() => {
      redirectToDetail();
    }, 1000);
  }

  const redirectToDetail = () => {
    history.push(`/companyDetail/${id}`);
  };

  if (!jobOffer) {
    return (
      <div>
        <p>loading...</p>
      </div>
    );
  } else {
    return (
      <div>
        <RS.Form>
          <RS.FormGroup>
            <RS.Label for="jobOfferCompany">Unternehmen</RS.Label>
            <RS.Input
              type="text"
              readOnly
              value={company.name || ""}
              id="jobOfferCompany"
            />
          </RS.FormGroup>

          <RS.FormGroup>
            <RS.Label for="jobOfferCompany">Standort(e)</RS.Label>
            <VariSelect
              model={Location}
              preselectedValues={site}
              optionSetter={setSite}
              displayValue="address"
            />
          </RS.FormGroup>

          <RS.FormGroup>
            <RS.Label for="jobOfferTitle">Jobtitel</RS.Label>
            <RS.Input
              type="text"
              name="title"
              id="jobOfferTitle"
              placeholder="Titel"
              value={title}
              onChange={(event) => setTitle(event.target.value)}
            />
          </RS.FormGroup>

          <RS.FormGroup>
            <RS.Label for="jobOfferCompany">Funktionsbereich(e)</RS.Label>
            <VariSelect
              model={FunctionalArea}
              preselectedValues={functionalAreas}
              optionSetter={setFunctionalAreas}
              displayValue="name"
            />
          </RS.FormGroup>

          <RS.FormGroup>
            <RS.Label for="jobOfferCompany">Spezialisierung(en)</RS.Label>
            <VariSelect
              model={Specialization}
              preselectedValues={specializations}
              optionSetter={setSpecializations}
              displayValue="name"
            />
          </RS.FormGroup>

          <RS.FormGroup>
            <RS.Label for="careerLevelSelect">Karrierestufe</RS.Label>
            <RS.Input
              onChange={(event) => setCareerLevel(event.target.value)}
              type="select"
              name="careerLevel"
              id="careerLevelSelect"
              value={careerLevel}
            >
              {CareerLevelData.map((level) => (
                <option key={level.id}>{level.label}</option>
              ))}
            </RS.Input>
          </RS.FormGroup>

          <RS.FormGroup>
            <RS.Label for="contractType">Vertragsart</RS.Label>
            <RS.Input
              type="select"
              name="contractType"
              id="contractType"
              value={contractType}
              onChange={(event) => setContractType(event.target.value)}
            >
              {ContractTypeData.map((contract) => (
                <option key={contract.id}>{contract.label}</option>
              ))}
            </RS.Input>
          </RS.FormGroup>

          <RS.FormGroup>
            <RS.Label for="jobStartDate">Frühester Beginn</RS.Label>
            <RS.Input
              type="date"
              name="jobStartDate"
              id="jobStartDate"
              value={jobStartDate}
              onChange={(event) => {
                setJobStartDate(event.target.value);
              }}
            />
          </RS.FormGroup>

          <RS.FormGroup>
            <RS.Label for="validFrom">Anzeige ist gültig von</RS.Label>
            <RS.Input
              type="date"
              name="validFrom"
              id="validFrom"
              value={validFrom}
              onChange={(event) => {
                setValidFrom(event.target.value);
              }}
            />
          </RS.FormGroup>

          <RS.FormGroup>
            <RS.Label for="validUntil">Anzeige ist gültig bis</RS.Label>
            <RS.Input
              type="date"
              name="validUntil"
              id="validUntil"
              value={validUntil}
              onChange={(event) => {
                setValidUntil(event.target.value);
              }}
            />
          </RS.FormGroup>

          <SuggestionEditor
            label="Aufgaben"
            suggestionTemplates={taskTemplates}
            suggestionDataHandler={setTaskData}
            suggestionData={taskData}
          />

          <SuggestionEditor
            label="Qualifikationen"
            suggestionTemplates={qualificationTemplates}
            suggestionDataHandler={setQualificationData}
            suggestionData={qualificationData}
          />

          <RS.FormGroup check className="mb-3">
            <RS.Label check>
              <RS.Input
                name="isEnabled"
                type="checkbox"
                checked={isEnabled}
                onChange={(event) => setIsEnabled(event.target.checked)}
              />{" "}
              Freigabe
            </RS.Label>
          </RS.FormGroup>

          <RS.Button className="bg-primary" onClick={updateJobOffer}>
            Stellenangebot speichern
          </RS.Button>
          <RS.Fade in={fadeIn} tag="h5" style={{ marginTop: "1em" }}>
            <RS.Alert color="success">Erfolgreich gespeichert</RS.Alert>
          </RS.Fade>
        </RS.Form>
      </div>
    );
  }


}

export default JobEditForm
