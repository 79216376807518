import React, { useState } from "react";
import { Link } from "react-router-dom";

import * as RS from "reactstrap";
import styles from '../theme/DropdownToggle.module.css';

import { AmplifySignOut } from "@aws-amplify/ui-react";

function ProfileDropdown({user}) {
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  console.log(user);
  return (
    <RS.ButtonDropdown
      className="dropdownProfile"
      isOpen={dropdownOpen}
      toggle={toggle}
      style={{ float: "right", width: '200px'}}
    >
      <RS.DropdownToggle caret className={styles.DropdownToggle}>
        <img
          src="/avatar_boris_bosse.png"
          alt={`${user.attributes.given_name} ${user.attributes.family_name}`}
          className={styles.portrait}
        />
        {`${user.attributes.given_name} ${user.attributes.family_name}`}
      </RS.DropdownToggle>

      <RS.DropdownMenu right className={styles.DropdownMenu}>
        <RS.DropdownItem header className={styles.DropdownItemHeader}>
          Mein Konto
        </RS.DropdownItem>
        <Link to="/settings" className={styles.link}>
          <RS.DropdownItem>Einstellungen</RS.DropdownItem>
        </Link>
        <Link to="/" className={styles.link}>
          <RS.DropdownItem>
            <AmplifySignOut style={{backgroundColor: 'transparent !important'}} />
          </RS.DropdownItem>
        </Link>
      </RS.DropdownMenu>
    </RS.ButtonDropdown>
  );
}

export default ProfileDropdown;
