import React from "react";

import { NavLink } from "react-router-dom";
import * as BI from "react-bootstrap-icons";

function SidebarItem({ label, link, exact=false,icon=<BI.ChevronRight /> }) {
  return (
    <NavLink
      to={link}
      className="nav-link text-left"
      exact={exact}
      style={{ width: '100%', paddingLeft: '0', paddingRight: '0', color: "var(--gray-700)",borderBottomStyle:"solid", borderBottomWidth: '1px', borderBottomColor: 'var(--secondary)' }}
    >
      {label} {icon}
    </NavLink>
  );
}
 export default SidebarItem;