import React from 'react'

import * as RS from 'reactstrap';

const JobCreateModal = ({modal, toggle, jobOffers, createNewAndRedirectFn, copyAndCreateFn}) => {
	return (
    <RS.Modal isOpen={modal} toggle={toggle} size="lg">
      <RS.ModalHeader toggle={toggle}>Neues Stellenangebot</RS.ModalHeader>
      <RS.ModalBody className="text-center">
        <RS.Button
          onClick={() => {
            createNewAndRedirectFn();
          }}
          className="bg-primary text-white m-4"
        >
          Neu erstellen
        </RS.Button>
        {jobOffers.length > 0 ? (
          <h6 className="text-secondary m-3">oder aus Vorlage erstellen</h6>
        ) : (
          ""
        )}

        <div style={{ maxHeight: "40vh", overflowY: "auto" }}>
          {jobOffers.map((job) => (
            <RS.Card key={job.id} className="bg-light text-left mb-1">
              <RS.CardBody className="pb-2 pt-3 d-flex justify-content-between">
                <div>
                  <strong>{job.title}</strong>
                  <br />
                  <small className="color-secondary">{job.contractType}</small>
                </div>
                <div>
                  <RS.Button
                    className="outline"
                    onClick={() => {
                      copyAndCreateFn(job);
                    }}
                    style={{
                      fontWeight: 400,
                      fontSize: ".865em",
                      color: "var(--gray-900)",
                      backgroundColor: "var(--gray-100)",
                    }}
                  >
                    Als Vorlage nutzen
                  </RS.Button>
                </div>
              </RS.CardBody>
            </RS.Card>
          ))}
        </div>
      </RS.ModalBody>
      <RS.ModalFooter
        style={{ justifyContent: "space-between" }}
      ></RS.ModalFooter>
    </RS.Modal>
  );
}

export default JobCreateModal
