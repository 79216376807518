import logo from "../logo.svg";

const Logo = () => {
	return (
		<img
		src={logo}
		alt="ONE Job logo"
		width="183"
		height="29"
		style={{ marginTop: "2em", marginBottom: "2em" }}
		/>
  );
}

export default Logo
