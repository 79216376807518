import React from 'react'
import { Link } from "react-router-dom";

import HeaderBar from "../components/HeaderBar";
import Sidebar from "../components/Sidebar";
import SidebarItem from "../components/SidebarItem";
import Main from "../components/Main";
import PageHeader from "../components/PageHeader";

import * as RS from "reactstrap";

const Settings = ({user}) => {

return (
  <RS.Container className="container-fluid">
    <HeaderBar
      className="nav"
      user={user}
      breadcrumb={
        <>
          <RS.BreadcrumbItem>
            <Link to="/">Home</Link>
          </RS.BreadcrumbItem>
          <RS.BreadcrumbItem active>Einstellungen</RS.BreadcrumbItem>
        </>
      }
    ></HeaderBar>
    <RS.Row>
      <Sidebar>
        <SidebarItem label="Kontoübersicht" link="/overview" />
      </Sidebar>
      <Main className="p-3">
        <PageHeader
          subheader="ONE Job"
          header={`Guten Tag ${user.attributes.given_name} ${user.attributes.family_name}`}
        />
        <h3>Ihre Einstellungen</h3>
      </Main>
    </RS.Row>
  </RS.Container>
);
}
export default Settings
