// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Location, CareerLevel, Specialization, FunctionalArea, JobOffer, Company } = initSchema(schema);

export {
  Location,
  CareerLevel,
  Specialization,
  FunctionalArea,
  JobOffer,
  Company
};