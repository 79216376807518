const QualificationTemplateData = [
  {
    id: 0,
    label: "mit Studium",
    data:
      "<ul><li>abgeschlossenes Studium</li><li>1 Jahr Berufserfahrung</li></ul>",
  },
  {
    id: 1,
    label: "mit Ausbildung",
    data:
      "<ul><li>abgeschlossene Lehre</li><li>langjährige Berufserfahrung</li></ul>",
  },
  {
    id: 2,
    label: "mit Archicad",
    data:
      "<ul><li>abgeschlossenes Studium</li><li>Erfahrung im Umgang mit Archicad</li></ul>",
  },
  {
    id: 3,
    label: "mit Studium und Erfahrung",
    data:
      "<ul><li>abgeschlossenes Studium</li><li>langjährige Berufserfahrung</li></ul>",
  },
];

export default QualificationTemplateData;
