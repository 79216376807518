import React from 'react'
import * as RS from "reactstrap";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const SuggestionEditor = ({label, suggestionTemplates, suggestionData, suggestionDataHandler, defaultOptionLabel ,editorConfig}) => {
	const setSuggestionTemplate = (event) => {
    if (!event.target.value) {
      suggestionDataHandler("Vorlage wählen");
    }
    const activeTemplate = suggestionTemplates.find(
      (x) => x.id === parseInt(event.target.value)
    );
    if (activeTemplate) {
      suggestionDataHandler(activeTemplate.data);
    } else {
      suggestionDataHandler("Frei verfassen oder aus Vorlage wählen");
    }
  };

	return (
    <RS.FormGroup key={`${label}-formgroup`}>
      <RS.Label>{label}</RS.Label>
      <RS.Input
        type="select"
        name={`${label}-templateSelect`}
        onChange={setSuggestionTemplate}
        className="mb-2"
      >
        <option key={`${label}-defaultOption`}>Textvorlage wählen</option>
        {suggestionTemplates.map((suggestion) => (
          <option key={suggestion.id} value={suggestion.id}>
            {suggestion.label}
          </option>
        ))}
      </RS.Input>
      <CKEditor
        editor={ClassicEditor}
        data={suggestionData}
        config={{
          toolbar: [["Bold"], ["Italic"], ["bulletedList"]],
        }}
        onChange={(event, editor) => {
          suggestionDataHandler(editor.getData());
        }}
      />
    </RS.FormGroup>
  );
}

export default SuggestionEditor
