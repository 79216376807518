import React from 'react'

import { Link } from "react-router-dom";

import * as RS from "reactstrap";
import * as BI from "react-bootstrap-icons";

import { DataStore } from "@aws-amplify/datastore";
import { Company, JobOffer } from "../models";

import HeaderBar from '../components/HeaderBar';
import Sidebar from '../components/Sidebar';
import SidebarItem from '../components/SidebarItem';
import Main from '../components/Main';
import PageHeader from '../components/PageHeader';

import styles from '../theme/RSTable.module.css';

const Overview = ({user}) => {
  const [companies, setCompanies] = React.useState([]);
  const [jobOffers, setJobOffers] = React.useState([]);


  React.useEffect(() => {
    fetchCompaniesAndOffers();
    async function fetchCompaniesAndOffers() {
      const companyData = await DataStore.query(Company);
      const jobOffers = await DataStore.query(JobOffer);
      setCompanies(companyData);
      setJobOffers(jobOffers);
    }

    DataStore.observe(Company).subscribe(() => {
      fetchCompaniesAndOffers();
    })
  }, []);



  return (
    <RS.Container className="container-fluid">
      <HeaderBar
        className="nav"
        user={user}
        breadcrumb={<RS.BreadcrumbItem>Home</RS.BreadcrumbItem>}
      ></HeaderBar>
      <RS.Row>
        <Sidebar>
          <SidebarItem label="Kontoübersicht" link="/overview" icon={<BI.ChevronRight />} />

        </Sidebar>
        <Main>
          <PageHeader
            subheader="ONE Job"
            header={`Guten Tag ${user.attributes.given_name} ${user.attributes.family_name}`}
          />
          <div className="bg-white p-3 pb-1">
            <h3 style={{ textAlign: "left" }}>Unternehmen verwalten</h3>
          </div>
          <RS.Table className={styles.RSTable}>
            <thead>
              <tr>
                <th className="pt-3 pb-3">Unternehmen</th>
                <th className="pt-3 pb-3">Stellenangebote</th>
                <th className="pt-3 pb-3"></th>
              </tr>
            </thead>
            <tbody>
              {companies.map((company) => (
                <tr key={company.id}>
                  <td>
                    <img src="foo.svg" alt="logo" className="pr-3" />
                    <Link
                      style={{ color: "var(--gray-900)" }}
                      to={`/companyDetail/${company.id}`}
                    >
                      <strong>{company.name}</strong>
                    </Link>
                  </td>
                  <td>{jobOffers.filter((job) => {
                    return job.companyID === company.id;
                  }).length}</td>
                  <td style={{textAlign: 'right'}}>
                    <Link
                      style={{ color: "var(--gray-900)" }}
                      to={`/companyDetail/${company.id}`}
                    >
                      <RS.Button outline>Auswählen</RS.Button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </RS.Table>
        </Main>
      </RS.Row>
    </RS.Container>
  );
};

export default Overview
