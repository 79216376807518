import React from 'react'
import {
  AmplifyAuthenticator,
  AmplifySignUp,
  AmplifySignIn,
} from "@aws-amplify/ui-react";

const AWSAuthenticator = () => {

	const MyTheme = {
	signInButton: { backgroundColor: "green" },
	googleSignInButton: { backgroundColor: "red", borderColor: "red" },
	};
	return (
		<AmplifyAuthenticator theme={MyTheme}>
		<AmplifySignUp
			slot="sign-up"
			headerText="Registrierung"
			submitButtonText="Registrieren"
			formFields={[
			{
				type: "username",
				label: "E-Mail-Adresse",
				placeholder: "Ihre Mail-Adresse",
			},
			{
				type: "given_name",
				label: "Vorname",
				placeholder: "Ihr Vorname",
			},
			{
				type: "family_name",
				label: "Nachname",
				placeholder: "Ihr Nachname",
			},
			{
				type: "password",
				label: "Passwort",
				placeholder: "Passwort vergeben",
				className: "btn btn-primary",
			},
			{ type: "email", label: "E-Mail-Adresse" },
			]}
		/>
		<AmplifySignIn
			headerText="Anmeldung"
			slot="sign-in"
			submitButtonText="Anmelden"
		></AmplifySignIn>
		</AmplifyAuthenticator>
	);
}

export default AWSAuthenticator
