import React from 'react'

import { DataStore } from "@aws-amplify/datastore";
import styles from "../theme/TagInput.module.css";

import { Multiselect } from "multiselect-react-dropdown";

const VariSelect = ({displayValue='name',model,preselectedValues=[],optionSetter}) => {
	const [availableOptions, setAvailableOptions] = React.useState([]);

	const handleAreaChange = (selectedOptions) => {
    optionSetter(selectedOptions);
	};

	React.useEffect(() => {
    fetchData();
    async function fetchData() {
			const models = await DataStore.query(model);
      setAvailableOptions(models);
    }
	}, [model]);

	return (
    <Multiselect
      className={styles.TagInput}
      options={availableOptions} // Options to display in the dropdown
      selectedValues={preselectedValues} // Preselected value to persist in dropdown
      onSelect={handleAreaChange} // Function will trigger on select event
      onRemove={handleAreaChange} // Function will trigger on remove event
      displayValue={displayValue} // Property name to display in the dropdown options
    />
  );
}

export default VariSelect
