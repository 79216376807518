import React from "react";

import * as RS from "reactstrap";

function Main(props) {
  return (
    <RS.Col
      sm="10"
      style={{
        backgroundColor: "var(--gray-200)",
        paddingLeft: "3em",
        paddingRight: "3em",
        paddingBottom: "2em",
      }}
    >
      {props.children}
    </RS.Col>
  );
}

export default Main;
