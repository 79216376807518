const Dict = {
  de: {
    "Sign In": "Anmelden",
    "Sign in": "Anmelden",
    "Sign Up": "Registrieren",
    "Sign Out": "Abmelden",
    "Enter your username": "Benutzernamen eingeben",
    "Username *": "Benutzername *",
    "Password *": "Passwort *",
    "Enter your password": "Passwort eingeben",
    "Enter your email address": "E-Mail-Adresse eingeben",
    "Create account": "Registrieren",
    "No account?": "Kein Konto?",
    "Reset password": "Passwort zurücksetzen",
    "Reset your password": "Ihr Passwort zurücksetzen",
    "Forgot your password?": "Passwort vergessen?",
    "Back to Sign In": "Zur Anmeldung",
    "Send Code": "Code abschicken",
    "Have an account?": "Konto bereits vorhanden?",
    "Username cannot be empty": "Bitte Benutzernamen ausfüllen.",
    "Password cannot be empty": "Bitte Passwort ausfüllen.",
    "Attribute value for family_name must not be null":
      "Bitte Nachname ausfüllen.",
    "Attribute value for email must not be null":
      "Bitte E-Mail-Adresse ausfüllen.",
    "Custom auth lambda trigger is not configured for the user pool.":
      "Bitte alle Felder ausfüllen.",
    "User does not exist.": "Dieser Nutzer existiert nicht.",
    "Incorrect username or password.": "Benutzername oder Kennwort falsch.",
    "Account recovery requires verified contact information":
      "Kontowiederherstellung erfordert verifizierte Kontaktinformationen.",
    Skip: "Später erledigen",
    Verify: "Überprüfen",
  },
};

export default Dict;