const ContractTypeData = [
  {
    id: 0,
    label: "Vertragsart wählen",
  },
  {
    id: 1,
    label: "Vollzeit",
  },
  {
    id: 2,
    label: "Teilzeit",
  },
];

export default ContractTypeData;
