import React from 'react'

import * as RS from "reactstrap";
import * as BI from "react-bootstrap-icons";
import { Link } from "react-router-dom";


const CompanyDropdown = function ({
  companies,
  selectedDropdown,
  dropdownOpen,
  toggle,
  handleDropdownChange,
}) {
  return (
    <RS.ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
      <RS.DropdownToggle
        caret
        className="nav-link"
        style={{
          outline: "none",
          border: "none",
          backgroundColor: "white",
          color: "var(--gray-700)",
          fontWeight: "bold",
          paddingLeft: 0,
          paddingRight: 0,
          width: "100%",
        }}
      >
        <span style={{ paddingRight: "1em" }}>{selectedDropdown}</span>
        <BI.ChevronDown />
      </RS.DropdownToggle>
      <RS.DropdownMenu>
        {companies.map((company, i) => {
          return (
            <Link
              to={`/companyDetail/${company.id}`}
              key={i}
              style={{ color: "var(--gray-900)" }}
            >
              <RS.DropdownItem
                key={i}
                onClick={() => {
                  handleDropdownChange(company);
                }}
              >
                <strong>
                  <img src="/foo.svg" alt="logo" className="pr-3" />
                  {company.name}
                </strong>
              </RS.DropdownItem>
            </Link>
          );
        })}
      </RS.DropdownMenu>
    </RS.ButtonDropdown>
  );
};

export default CompanyDropdown
