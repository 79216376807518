const TaskTemplatesData= [
  {
    id: 0,
    label: "Straßenbau",
    data: "<ul><li>Projektleitung</li><li>Straßenbau</li></ul>",
  },
  {
    id: 1,
    label: "Tiefbau",
    data: "<ul><li>Projektleitung</li><li>Tiefbau</li></ul>",
  },
  {
    id: 2,
    label: "Gleisbau",
    data: "<ul><li>Projektleitung</li><li>Gleisbau</li></ul>",
  },
  {
    id: 3,
    label: "Kanalbau",
    data: "<ul><li>Projektleitung</li><li>Kanalbau</li></ul>",
  },
];

export default TaskTemplatesData;