import React, { useState } from "react";
import { Link } from "react-router-dom";

import * as RS from "reactstrap";
import * as BI from "react-bootstrap-icons";
import { DataStore } from "@aws-amplify/datastore";
import { JobOffer } from "../models";

import styles from "../theme/DropdownToggle.module.css";

function JobContextMenu({ job, deleteHandler, location, modalToggle }) {
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);

  const toggleIsEnabled = (jobToToggle) => {
    updateJobOffer();
    async function updateJobOffer() {
      await DataStore.save(
        JobOffer.copyOf(jobToToggle, (item) => {
          item.isEnabled = !jobToToggle.isEnabled;
        })
      );
    }
  }

  return (
    <RS.ButtonDropdown className="jobActionDropDown" direction="left" isOpen={dropdownOpen} toggle={toggle} >
      <RS.DropdownToggle caret className={styles.DropdownToggle}>
        <span style={{fontWeight: 800, fontSize: "2em", color: "var(--secondary)",}}>
          ⋮
        </span>
      </RS.DropdownToggle>

      <RS.DropdownMenu right className={styles.DropdownMenu}>
        <RS.Button onClick={() => { deleteHandler(job); modalToggle(); }} >
          <BI.Trash />
        </RS.Button>
        {/* edit button */}
        <Link to={`${location.pathname}/editJobOffer/${job.id}`}>
          <RS.Button>
            <BI.Pen />
          </RS.Button>
        </Link>
        {/* pause/unpause button */}
          <RS.Button onClick={() => { toggleIsEnabled(job) }}>
            {job.isEnabled ? <BI.Pause /> : <BI.Play />}
          </RS.Button>
      </RS.DropdownMenu>
    </RS.ButtonDropdown>
  );
}

export default JobContextMenu;
