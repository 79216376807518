import React, { useState } from 'react'

import { Link, useHistory } from "react-router-dom";
import { DataStore } from "@aws-amplify/datastore";
import { JobOffer } from "../models";

import VariSelect from "./VariSelect";
import SuggestionEditor from "./SuggestionEditor";
import { FunctionalArea, Specialization, Location } from '../models';

import TaskTemplatesData from '../data/TaskTemplatesData';
import QualificationTemplateData from "../data/QualificationTemplateData";
import CareerLevelData from "../data/CareerLevelData";
import ContractTypeData from "../data/ContractTypeData";
import { nanoid } from "nanoid";

import * as RS from 'reactstrap';
import * as BI from 'react-bootstrap-icons';

const JobForm = ({company, jobOffer=null }) => {
  const history = useHistory();
  const [shortId, ] = useState(nanoid(5));

  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [functionalAreas, setFunctionalAreas] = useState([]);
  const [specializations, setSpecializations] = useState([]);
  const [locations, setLocations] = useState([]);
  const [taskData, setTaskData] = useState("Frei verfassen oder aus Vorlage wählen");
  const [qualificationData,setQualificationData] = useState('Frei verfassen oder aus Vorlage wählen');
  const [jobStartDate, setJobStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [validFrom, setValidFrom] = useState(new Date().toISOString().split('T')[0]);
  const [validUntil, setValidUntil] = useState(
    new Date(new Date().setFullYear(new Date().getFullYear() + 1))
      .toISOString()
      .split("T")[0]
  );

  // import stub data here
  const taskTemplates = TaskTemplatesData;
  const qualificationTemplates = QualificationTemplateData;

  const [fadeIn, setFadeIn] = useState(false);

  const initialFormData = Object.freeze({
    title: "",
    companyID: company.id,
    functionalArea: [],
    specialization: [],
    careerLevel: "",
    site: [],
    contractType: "",
    isEnabled: false,
    jobStartDate: '',
    validFrom: '',
    validUntil: '',
    nanoId: shortId,
    tasks: '',
    qualifications: '',
    promoteOnCorporate: false,
    promoteOnXing: false,
    promoteOnLinkedIn: false,
    promoteOnIndeed: false
    //createdAt: this seems to be created automatically
  });
  const [formData, updateFormData] = useState(initialFormData)

  const changeJobStartDate = (event) => {
    setJobStartDate(event.target.value);
  };

  const changeValidFrom = (event) => {
    setValidFrom(event.target.value);
  };

  const changeValidUntil = (event) => {
    setValidUntil(event.target.value);
  };

	const handleChange = (event) => {
    let value = event.target.value.trim();
    // handle boolean case (checkbox)
    if(event.target.name.startsWith('isEnabled') || event.target.name.startsWith('promoteOn')) {
      value = event.target.checked;
    }
		updateFormData({
      ...formData,
      [event.target.name]: value,
    });
  }

	const handleSubmit = (event) => {
    event.preventDefault();
    saveJobOffer().then((data) => {
      setFadeIn(true);
      setTimeout(() => {
        redirectToReview(data.id);
      }, 1000);
    });
  }

	async function saveJobOffer() {
    const tempJobOffer = new JobOffer({
      title: formData.jobOfferTitle,
      companyID: company.id,
      functionalArea: functionalAreas.map((area) => area.name),
      specialization: specializations.map((area) => area.name),
      careerLevel: formData.careerLevel,
      site: locations.map((location) => JSON.stringify(location.address)),
      contractType: formData.contractType,
      isEnabled: formData.isEnabled,
      //createdAt: this seems to be created automatically
      jobStartDate: jobStartDate,
      validFrom: validFrom,
      validUntil: validUntil,
      nanoId: shortId,
      tasks: taskData,
      qualifications: qualificationData,
      promoteOnCorporate: formData.promoteOnCorporate,
      promoteOnXing: formData.promoteOnXing,
      promoteOnLinkedIn: formData.promoteOnLinkedIn,
      promoteOnIndeed: formData.promoteOnIndeed
    });
    await DataStore.save(tempJobOffer);
    return tempJobOffer;
  }

  const redirectToReview = (newId)  => {
    history.push(`/companyDetail/${company.id}/reviewJobOffer/${newId}`);
  }

	return (
    <>
      <RS.Nav tabs style={{ alignItems: "flex-end" }}>
        <RS.NavItem>
          <RS.NavLink
            className={activeTab === "1" ? "active p-3" : "p-3"}
            onClick={() => {
              toggle("1");
            }}
          >
            1. Stellendaten
          </RS.NavLink>
        </RS.NavItem>
        <RS.NavItem>
          <RS.NavLink
            className={activeTab === "2" ? "active p-3" : "p-3"}
            onClick={() => {
              toggle("2");
            }}
          >
            2. Platzierung
          </RS.NavLink>
        </RS.NavItem>
      </RS.Nav>
      <RS.Row>
        <RS.Col className="col-sm-12 col-md-10 col-lg-6">
          <RS.Form>
            <RS.TabContent activeTab={activeTab}>
              <RS.TabPane
                tabId="1"
                style={{ backgroundColor: "white" }}
                className="p-3"
              >
                <RS.FormGroup>
                  <RS.Label for="jobOfferCompany">Unternehmen</RS.Label>
                  <RS.Input
                    type="text"
                    readOnly
                    value={company.name || ""}
                    id="jobOfferCompany"
                  />
                </RS.FormGroup>

                <RS.FormGroup>
                  <RS.Label for="jobOfferCompany">Standort(e)</RS.Label>
                  <VariSelect
                    model={Location}
                    preselectedValues={[]}
                    optionSetter={setLocations}
                    displayValue="address"
                  />
                </RS.FormGroup>

                <RS.FormGroup>
                  <RS.Label for="jobOfferTitle">Jobtitel</RS.Label>
                  <RS.Input
                    type="text"
                    name="jobOfferTitle"
                    id="jobOfferTitle"
                    placeholder="Titel"
                    onChange={handleChange}
                  />
                </RS.FormGroup>

                <RS.FormGroup>
                  <RS.Label for="jobOfferCompany">Funktionsbereich(e)</RS.Label>
                  <VariSelect
                    model={FunctionalArea}
                    preselectedValues={[]}
                    optionSetter={setFunctionalAreas}
                  />
                </RS.FormGroup>

                <RS.FormGroup>
                  <RS.Label for="jobOfferCompany">Spezialisierung(en)</RS.Label>
                  <VariSelect
                    model={Specialization}
                    preselectedValues={[]}
                    optionSetter={setSpecializations}
                  />
                </RS.FormGroup>

                <RS.FormGroup>
                  <RS.Label for="careerLevelSelect">Karrierestufe</RS.Label>
                  <RS.Input
                    onChange={handleChange}
                    type="select"
                    name="careerLevel"
                    id="careerLevelSelect"
                  >
                    {CareerLevelData.map((careerLevel) => (
                      <option key={careerLevel.id}>{careerLevel.label}</option>
                    ))}
                  </RS.Input>
                </RS.FormGroup>

                <RS.FormGroup>
                  <RS.Label for="contractType">Vertragsart</RS.Label>
                  <RS.Input
                    type="select"
                    name="contractType"
                    id="contractType"
                    onChange={handleChange}
                  >
                    {ContractTypeData.map((contract) => (
                      <option key={contract.id}>{contract.label}</option>
                    ))}
                  </RS.Input>
                </RS.FormGroup>

                <RS.FormGroup>
                  <RS.Label for="jobStartDate">Frühester Beginn</RS.Label>
                  <RS.Input
                    type="date"
                    name="jobStartDate"
                    id="jobStartDate"
                    value={jobStartDate}
                    onChange={changeJobStartDate}
                  />
                </RS.FormGroup>

                <RS.FormGroup>
                  <RS.Label for="validFrom">Anzeige ist gültig von</RS.Label>
                  <RS.Input
                    type="date"
                    name="validFrom"
                    id="validFrom"
                    value={validFrom}
                    onChange={changeValidFrom}
                  />
                </RS.FormGroup>

                <RS.FormGroup>
                  <RS.Label for="validUntil">Anzeige ist gültig bis</RS.Label>
                  <RS.Input
                    type="date"
                    name="validUntil"
                    id="validUntil"
                    value={validUntil}
                    onChange={changeValidUntil}
                  />
                </RS.FormGroup>

                <RS.FormGroup style={{ display: "none" }}>
                  <RS.Label for="nanoId">Job-Id</RS.Label>
                  <RS.Input
                    type="text"
                    name="nanoId"
                    id="nanoId"
                    value={shortId}
                    placeholder="Titel"
                    onChange={handleChange}
                    readOnly
                  />
                </RS.FormGroup>

                <SuggestionEditor
                  label="Aufgaben"
                  suggestionTemplates={taskTemplates}
                  suggestionDataHandler={setTaskData}
                  suggestionData={taskData}
                />

                <SuggestionEditor
                  label="Qualifikationen"
                  suggestionTemplates={qualificationTemplates}
                  suggestionDataHandler={setQualificationData}
                  suggestionData={qualificationData}
                />

                <RS.FormGroup check className="mb-3">
                  <RS.Label check>
                    <RS.Input
                      name="isEnabled"
                      type="checkbox"
                      onChange={handleChange}
                    />{" "}
                    Freigabe
                  </RS.Label>
                </RS.FormGroup>
              </RS.TabPane>
              <RS.TabPane
                tabId="2"
                style={{ backgroundColor: "white" }}
                className="p-3"
              >
                <h3 className="mt-3 mb-3">Online</h3>
                <RS.FormGroup check className="mb-3">
                  <RS.Label check>
                    <RS.Input
                      name="promoteOnCorporate"
                      type="checkbox"
                      onChange={handleChange}
                    />{" "}
                    Matthäi Karriere Website
                  </RS.Label>
                </RS.FormGroup>
                <RS.FormGroup check className="mb-3">
                  <RS.Label check>
                    <RS.Input
                      name="promoteOnXing"
                      type="checkbox"
                      onChange={handleChange}
                    />{" "}
                    XING Stellenmarkt
                  </RS.Label>
                </RS.FormGroup>
                <RS.FormGroup check className="mb-3">
                  <RS.Label check>
                    <RS.Input
                      name="promoteOnLinkedIn"
                      type="checkbox"
                      onChange={handleChange}
                    />{" "}
                    LinkedIn
                  </RS.Label>
                </RS.FormGroup>
                <RS.FormGroup check className="mb-5">
                  <RS.Label check>
                    <RS.Input
                      name="promoteOnIndeed"
                      type="checkbox"
                      onChange={handleChange}
                    />{" "}
                    Indeed
                  </RS.Label>
                </RS.FormGroup>
                <RS.FormGroup>
                  <RS.Row>
                    <RS.Col>
                      <Link to={`/companyDetail/${company.id}`}>
                        <RS.Button className="bg-secondary text-white">
                          <BI.X style={{ fontSize: "1.7rem" }} /> Abbrechen
                        </RS.Button>
                      </Link>
                    </RS.Col>
                    <RS.Col>
                      <RS.Button className="bg-primary" onClick={handleSubmit}>
                        Stellenangebot speichern
                      </RS.Button>
                      <RS.Fade
                        in={fadeIn}
                        tag="h5"
                        style={{ marginTop: "1em" }}
                      >
                        <RS.Alert color="success">
                          Erfolgreich gespeichert
                        </RS.Alert>
                      </RS.Fade>
                    </RS.Col>
                  </RS.Row>
                </RS.FormGroup>
              </RS.TabPane>
            </RS.TabContent>
          </RS.Form>
        </RS.Col>
      </RS.Row>
    </>
  );
}

export default JobForm
