import React, { useState } from "react";

import * as RS from "reactstrap";

import { DataStore } from "@aws-amplify/datastore";
import { Company } from "../models";

import CompanyDropdown from "./CompanyDropdown";

function Sidebar({ style = {}, className = "", cols = 2, ...otherProps }) {
  const classNames = `Sidebar ${className}`;
  const baseStyles = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  };
  const [dropdownOpen, setOpen] = useState(false);
  const [selectedDropdown, setSelectedDropdown] = useState(
    "Unternehmen wählen"
  );
  const toggle = () => setOpen(!dropdownOpen);
  const [companies, setCompanies] = React.useState([]);

  const handleDropdownChange = (company) => {
    setSelectedDropdown(`${company.name}`);
  };

  React.useEffect(() => {
    fetchCompaniesAndOffers();
    async function fetchCompaniesAndOffers() {
      const companyData = await DataStore.query(Company);
      setCompanies(companyData);
    }
    DataStore.observe(Company).subscribe(() => {
      fetchCompaniesAndOffers();
    });
  }, []);

  return (
    <RS.Col sm={cols}>
      <div className={classNames} style={{ ...style, ...baseStyles }}>
        <RS.Nav className={"nav align-items-start"} vertical={true}>
          {companies.length > 0 ? (
            <CompanyDropdown
              companies={companies}
              dropdownOpen={dropdownOpen}
              setOpen={setOpen}
              selectedDropdown={selectedDropdown}
              setSelectedDropdown={setSelectedDropdown}
              toggle={toggle}
              handleDropdownChange={handleDropdownChange}
            />
          ) : (
            <span>fetching</span>
          )}
          {otherProps.children}
        </RS.Nav>
      </div>
    </RS.Col>
  );
}

export default Sidebar;
