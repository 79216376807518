import React from "react";

import * as RS from "reactstrap";
import { Link } from "react-router-dom";

import logo from "../logo.svg";
import ProfileDropdown from "./ProfileDropdown";

function HeaderBar({ breadcrumb, user, ...otherprops }) {
  return (
    <RS.Row>
      <RS.Col sm="2" className="align-items-start display-flex">
        <Link className="nav-link" to="/">
          <img src={logo} alt="ONE Fleet logo" width="183" height="29" />
        </Link>
      </RS.Col>
      <RS.Col sm="10">
        <RS.Row className="display-flex justify-content-between">
          <RS.Col sm="6" className="align-items-start display-flex">
            <RS.Breadcrumb>{breadcrumb}</RS.Breadcrumb>
          </RS.Col>
          <RS.Col sm="6" className="display-flex justify-content-end">
            <ProfileDropdown user={user}/>
          </RS.Col>
        </RS.Row>
      </RS.Col>
    </RS.Row>
  );
}

export default HeaderBar;
