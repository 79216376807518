import React from 'react'

import * as RS from 'reactstrap';

const PageHeader = ({header='', subheader='', withDivider=true,...otherProps}) => {
  return (
    <div className="header text-left">
      <div className="header-body">
        <RS.Row>
          <RS.Col sm="8">
            <h6 className="header-pretitle">{subheader}</h6>

            <h1 className="header-title">{header}</h1>
          </RS.Col>
          <RS.Col sm="4">
            {otherProps.children}
          </RS.Col>
        </RS.Row>
      </div>
    </div>
  );
}

export default PageHeader;